import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'orders',
        name: 'Orders',
        type: 'link',
        icon: 'ti-shopping-cart'
      },
      {
        state: 'export',
        name: 'Export',
        type: 'link',
        icon: 'ti-export'
      },
      {
        state: 'import',
        name: 'Import',
        type: 'link',
        icon: 'ti-import'
      }
    ],
  },
  {
    label: 'Products',
    main: [
      {
        state: 'products-supplier',
        name: 'Suppliers',
        type: 'link',
        icon: 'ti-envelope'
      },
      {
        state: 'products-dropshipper',
        name: 'Dropshipper',
        type: 'link',
        icon: 'ti-envelope'
      },
      {
        state: 'category',
        name: 'Categories',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      {
        state: 'sub-category',
        name: 'Sub Categories',
        type: 'link',
        icon: 'ti-layout-grid2-thumb'
      },
    ],
  },
  {
    label: 'Estimate Shipment',
    main: [
      {
        state: 'pricing-calculator',
        name: 'Pricing Calculator',
        type: 'link',
        icon: 'ti-money'
      }, {
        state: 'pricing-zone',
        name: 'Pricing Zone DHL',
        type: 'link',
        icon: 'ti-money'
      }, {
        state: 'pricing-zone-ems',
        name: 'Pricing Zone EMS',
        type: 'link',
        icon: 'ti-money'
      }, {
        state: 'pricing-zone-cargo',
        name: 'Pricing Zone Cargo',
        type: 'link',
        icon: 'ti-money'
      },
      // {
      //   state: 'expeditions',
      //   name: 'Expeditions',
      //   type: 'link',
      //   icon: 'ti-truck'
      // },
      {
        state: 'history-calculator',
        name: 'History Calculator',
        type: 'link',
        icon: 'ti-archive'
      }
    ]
  },
  {
    label: 'People',
    main: [
      {
        state: 'stores',
        name: 'Dropshipper',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: 'suppliers',
        name: 'Suppliers',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: 'users',
        name: 'Customers',
        type: 'link',
        icon: 'ti-user'
      }
    ],
  },
  {
    label: 'Articles',
    main: [
      {
        state: 'blog',
        name: 'Article',
        type: 'link',
        icon: 'ti-bookmark'
      },
      {
        state: 'blog-category',
        name: 'Categories',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
    ],
  },
  {
    label: 'Support',
    main: [
      // {
      //   state: 'notifications',
      //   name: 'Notification',
      //   type: 'link',
      //   icon: 'ti-bell'
      // },
      {
        state: 'contacts',
        name: 'Chat',
        type: 'link',
        icon: 'ti-comments-smiley'
      },
      {
        state: 'emails',
        name: 'Contact',
        type: 'link',
        icon: 'ti-email'
      },
    ]
  },
  {
    label: 'Manage',
    main: [
      // {
      //   state: 'languages',
      //   name: 'Languages',
      //   type: 'link',
      //   icon: 'ti-world'
      // },
      // {
      //   state: 'banners',
      //   name: 'Banners',
      //   type: 'link',
      //   icon: 'ti-layout-list-large-image',
      // },
      // {
      //   state: 'offers',
      //   name: 'Coupons',
      //   type: 'link',
      //   icon: 'ti-medall'
      // },


      // {
      //   state: 'manage-app',
      //   name: 'Manage App',
      //   type: 'link',
      //   icon: 'ti-lock'
      // },
      // {
      //   state: 'send-mail',
      //   name: 'Send Emails',
      //   type: 'link',
      //   icon: 'ti-email'
      // },
      // {
      //   state: 'app-settings',
      //   name: 'App Settings',
      //   type: 'link',
      //   icon: 'ti-desktop'
      // },
      // {
      //   state: 'general',
      //   name: 'General',
      //   type: 'link',
      //   icon: 'ti-settings'
      // },
      // {
      //   state: 'app-pages',
      //   name: 'App Pages',
      //   type: 'link',
      //   icon: 'ti-blackboard'
      // },
      // {
      //   state: 'manage-popup',
      //   name: 'Manage Popup',
      //   type: 'link',
      //   icon: 'ti-quote-right'
      // },
      // {
      //   state: 'payment',
      //   name: 'Payments',
      //   type: 'link',
      //   icon: 'ti-money'
      // }
      {
        state: 'administrantor',
        name: 'Administrator',
        type: 'link',
        icon: 'ti-id-badge'
      },
      // {
      //   state: 'manage-website',
      //   name: 'Manage Websites',
      //   type: 'link',
      //   icon: 'ti-layout-width-default'
      // }

    ]
  },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
